import { reactive, ref } from 'vue';

export default (params) => {
  const formRef = ref();
  type CommuniData = {
    operateType: number;
    topic?: string;
    contactTime?: string;
    content?: string;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    operateType: params.formType,
    eventId: params.eventId,
    eventType: params.eventType,
    topic: '',
    contactTime: '',
    content: '',
    ...params,
  });
  const rules = reactive({
    topic: [
      {
        required: true,
        message: '请输入主题',
        trigger: 'blur',
      },
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    contactTime: [
      {
        required: true,
        message: '请选择日期时间',
        trigger: 'blur',
      },
    ],
    content: [
      {
        required: true,
        message: '请输入沟通内容',
        trigger: 'blur',
      },
      {
        max: 1000,
        message: '长度限制为1000',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};
